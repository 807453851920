import { Currency, Scrollable } from 'core';

export enum CardType {
  VIRTUAL = 'VIRTUAL',
  PHYSICAL = 'PHYSICAL',
  BOTH = 'BOTH',
}

export enum PhysicalCardStatus {
  NotOrdered = 'NotOrdered',
  Ordered = 'Ordered',
  Activated = 'Activated',
  Lost = 'Lost',
}

export type PhysicalCardStatusResponse = {
  status: PhysicalCardStatus;
  canActivate: boolean;
  frozen: boolean;
  frozenUntil: Date | null;
  readonly normalMailFees: number;
  readonly expressMailFees: number;
  readonly replaceLostCardFees: number;
};

export interface CardUpgradeStatus {
  readonly atTopLevel: boolean;
  readonly previousVirtualCardLevel: string;
  readonly virtualCardLevel: string;
  readonly physicalCardLevel: string;
  readonly qualifyingLevel: string;
  readonly physicalCardUpgradeNeedsShipping: boolean;
  readonly lifetimeRewards: number;
}

export class RequestPhysicalCardResponse {
  constructor(readonly success: boolean, readonly transactionId: string, readonly failureMessage: string) {}
}

export class CardPaymentHistoryRequest {
  constructor(
    readonly size: number = 1000,
    readonly bookmarkPaymentId: string | null | undefined,
    public start: string,
    public end: string
  ) {}
}

export class CardPaymentRecord implements Scrollable<string> {
  private cardCurrenyRecords: Array<CardPaymentInCardCurrencyRecord>;
  private _spendRewards: number | null = null;

  constructor(private rawData: any, currenciesById: Map<string, Currency>) {
    this.cardCurrenyRecords = new Array<CardPaymentInCardCurrencyRecord>();
    const cardCurrencyRecordsByCurrency = new Map<string, CardPaymentInCardCurrencyRecord>();
    for (let i = 0; i < rawData.ledgerEntries.length; i++) {
      const le = rawData.ledgerEntries[i];
      if (le.entryType != 'SPEND_REWARD') {
        if (!cardCurrencyRecordsByCurrency.has(le.ledgerCurrencyCd)) {
          cardCurrencyRecordsByCurrency.set(
            le.ledgerCurrencyCd,
            new CardPaymentInCardCurrencyRecord(currenciesById.get(le.ledgerCurrencyCd)!, le.entryAmt)
          );
        } else {
          cardCurrencyRecordsByCurrency.get(le.ledgerCurrencyCd)!.addAmount(le.entryAmt);
        }
      } else {
        if (this._spendRewards == null) {
          this._spendRewards = le.entryAmt;
        } else {
          this._spendRewards += le.entryAmt;
        }
      }
    }

    cardCurrencyRecordsByCurrency.forEach((element) => this.cardCurrenyRecords.push(element));
  }

  public get spendRewards(): number | null {
    return this._spendRewards;
  }

  public get id(): string {
    return this.rawData.id;
  }

  public get paymentTs(): Date {
    return this.rawData.paymentTs;
  }

  public get settledTs(): Date {
    return this.rawData.settledTs;
  }

  public get description(): string {
    return this.rawData.description;
  }

  public get paymentAmt(): number {
    return this.rawData.paymentAmt;
  }

  public get localPaymentAmt(): number {
    return this.rawData.localPaymentAmt;
  }

  public get settledAmt(): number {
    return this.rawData.settledAmt;
  }

  public get isSettled(): boolean {
    return this.settledTs != null;
  }

  public get currencyCd(): string {
    return this.rawData.currencyCd;
  }

  public get localCurrencyCd(): string {
    return this.rawData.localCurrencyCd;
  }

  public get isInternational(): boolean {
    return (
      this.rawData.localCurrencyCd != null && this.rawData.currencyCd != null && this.rawData.localCurrencyCd != this.rawData.currencyCd
    );
  }

  public get cardSpendAmounts(): Array<CardPaymentInCardCurrencyRecord> {
    return this.cardCurrenyRecords;
  }

  public get displayAmt(): number {
    return this.settledAmt ? this.settledAmt : this.paymentAmt;
  }

  public get mccCode(): string {
    return this.rawData.mcc ?? '';
  }

  public get cardSuffix(): string {
    return this.rawData.cardSuffix ?? '';
  }
}

export class CardPaymentInCardCurrencyRecord {
  private _totalAmount: number;

  constructor(readonly currency: Currency, amount: number) {
    this._totalAmount = amount;
  }

  public addAmount(amount: number): void {
    this._totalAmount += amount;
  }

  public get totalAmount(): number {
    return this._totalAmount;
  }
}

export class PaymentTableList {
  public Description: string;
  public Date: string;
  public Amount: number;
  public MCC: string;
  public CardSuffix: string;
}
